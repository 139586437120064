import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/twitter-follow-retweet.png';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Twitterフォローリツイートキャンペーン"
		  desc="このサンプルはTwitterでもっとも人気と実績のあるフォロー&リツイートキャンペーンを行う方法です。拡散力が高く多くのフォロワーを獲得することができます。フォロワーからビジネスを知ってもらうきっかけを作り、エンゲージメント高めていくことに適しています。"
		  banner={exampleMetaImage}
		  pathname={'/examples/twitter/follow-retweet'}
		  type={'Article'}
		  shortName="Twitterのフォローリツイートキャンペーン"
		  createdAt="2021-11-26"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="Twitterフォローリツイートキャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="Twitterフォローリツイートキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/twitter',
		  name: 'Twitterキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Twitterフォロー&リツイートキャンペーン"}
		description={<>このサンプルはTwitterでもっとも人気と実績のあるフォロー&リツイートキャンペーンを行う方法です。拡散力が高く多くのフォロワーを獲得することができます。<br/>フォロワーからビジネスを知ってもらうきっかけを作り、エンゲージメント高めていくことに適しています。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS,Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.GET_HIGH_SPREAD, Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE, Constants.CAMPAIGN_EFFECTS.GAIN_RECOGNITION]}
		plan={'ベーシックプラン以上'}
		promotionId="b081c1bd5da1290cf2"
		platformExample={
		  <PlatformExample
			title="その他のTwitterキャンペーン"
			types={['twitterFollowRetweet', 'twitterFollowLike', 'twitterFollowHashtag','twitterToLine','twitterInstantWin','twitterMultipleAccounts']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples